import React, {useEffect} from 'react';
import Panel from './Panel';

const Cymraeg = () => {
  
  const gtmConfig = 'https://www.principality.co.uk';

  useEffect(() => {
    window.parent.postMessage('pageview~/virtual/cy/principality/SellingHome-checklist', gtmConfig);

    setInterval(() => {
      window.parent.postMessage(document.body.scrollHeight, gtmConfig);
    }, 250);
  }, []);

  return (
    
    <div className="App">
      <div className="checklist">
        <h1>Rhestr wirio ar gyfer gwerthu eich cartref</h1>
        <div className="intro">
          <p>Gall gwerthu tŷ fod yn broses hir a brawychus, ond gall bod yn barod a deall y camau helpu i wneud hyn mor rhwydd â phosibl.</p>
          <p>Dilynwch ein rhestr wirio ar gyfer gwerthiant eich cartref a pharhewch i fod yn drefnus yn ystod y cyfnod sy’n arwain at eich symudiad.</p>
        </div>

        <div className="panelsWrapper">

          <Panel
            title="1. Trefnu eich arian"
            panelKey={'SHCpanelOneCY'}
            langCode={'/cy/'}
            completeText={'Marc fel cyflawn'}
            incompleteText={'Marc fel anghyflawn'}
          >
            <p>Cyn i chi werthu eich cartref, bydd angen i chi wybod faint yw ei werth ef. Edrychwch ar dai eraill sydd wedi eu gwerthu yn ystod y flwyddyn ddiwethaf yn eich ardal i gael syniad o’r dosbarth prisiau.</p>
          </Panel>
          <Panel
            title="2. Dewis asiant eiddo"
            panelKey={'SHCpanelTwoCY'}
            langCode={'/cy/'}
            completeText={'Marc fel cyflawn'}
            incompleteText={'Marc fel anghyflawn'}
          >
            <p>Gwnewch rywfaint o waith ymchwil a dewiswch asiant eiddo blaenllaw i’ch helpu i werthu eich cartref. Bydd angen i chi weithio gyda’ch asiant eiddo hefyd i benderfynu ar bris gofyn.</p>
          </Panel>
          <Panel
            title="3. Paratoi eich cartref i’w werthu"
            panelKey={'SHCpanelThreeCY'}
            langCode={'/cy/'}
            completeText={'Marc fel cyflawn'}
            incompleteText={'Marc fel anghyflawn'}
          >
            <p>Tacluswch, ychwanegwch got ffres o baent a gwnewch yn siŵr bod eich cartref yn teimlo’n sgleiniog ac yn newydd.</p>
          </Panel>

          <Panel
            title="4. Gwneud cais am dystysgrif perfformiad ynni"
            panelKey={'SHCpanelFourCY'}
            langCode={'/cy/'}
            completeText={'Marc fel cyflawn'}
            incompleteText={'Marc fel anghyflawn'}
          >
            <p>Hefyd, dylech wneud cais am Dystysgrif Perfformiad Ynni cyn i chi roi eich cartref ar y farchnad.</p>
          </Panel>

          <Panel
            title="5. Dewis cyfreithiwr trawsgludo"
            panelKey={'SHCpanelFiveCY'}
            langCode={'/cy/'}
            completeText={'Marc fel cyflawn'}
            incompleteText={'Marc fel anghyflawn'}
          >
            <p>Dewiswch gyfreithiwr trawsgludo llwyddiannus sydd o fewn eich cyrraedd yn ariannol i reoli ochr gyfreithiol gwerthiant eich tŷ.</p>
          </Panel>

          <Panel
            title="6. Derbyn cynnig yr ydych yn hapus ag ef"
            panelKey={'SHCpanelSixCY'}
            langCode={'/cy/'}
            completeText={'Marc fel cyflawn'}
            incompleteText={'Marc fel anghyflawn'}
          >
            <p>Ar ôl i chi dderbyn cynnig gan ddarpar brynwr yr ydych yn hapus ag ef, gallwch dderbyn y cynnig hwn yn ffurfiol. Nid yw hyn yn gwneud y gwerthiant yn rhwymol yn gyfreithiol.</p>
            <p>Peidiwch â bod ofn gwrthod cynigion nad ydych yn hapus â nhw, na gofyn i’ch asiant eiddo negodi pris uwch.</p>
          </Panel>

          <Panel
            title="7. Cyfnewid contractau"
            panelKey={'SHCpanelSevenCY'}
            langCode={'/cy/'}
            completeText={'Marc fel cyflawn'}
            incompleteText={'Marc fel anghyflawn'}
          >
            <p>Ar ôl i chi dderbyn cynnig yn ffurfiol, bydd angen i chi ddrafftio a chyfnewid contractau gyda’r darpar brynwr. Mae cyfnewid contractau yn gwneud y gwerthiant yn rhwymol yn gyfreithiol a bydd yn ofynnol i chi werthu eich eiddo yn ôl y gyfraith.</p>
          </Panel>

          <Panel
            title="8. Cwblhau"
            panelKey={'SHCpanelEightCY'}
            langCode={'/cy/'}
            completeText={'Marc fel cyflawn'}
            incompleteText={'Marc fel anghyflawn'}
          >
            <p>Mae’n bryd rhoi eich allweddi i’r perchnogion newydd, oherwydd diwrnod cwblhau’r gwerthiant yw’r amser pan fo’r eiddo yn newid perchnogaeth.</p>
            <p>Fel arfer, bydd eich cyfreithiwr yn gofalu am dalu eich morgais ar ôl i arian y prynwr ddod i law. Fodd bynnag, bydd angen i chi dalu’r holl ffioedd sy’n ddyledus i’ch asiant eiddo a’ch cyfreithiwr.</p>
            <p>O’r diwedd, rydych wedi cyrraedd diwedd y broses o werthu eich tŷ!</p>
          </Panel>
        </div>
      </div>
    </div>
  )
};

export default Cymraeg;
import React, { useState, useEffect } from 'react';

const Panel = ({ panelKey, title, langCode, completeText, incompleteText, children }) => {

  const lang = langCode ? langCode : '';

  const [sectionActive, setSectionActive] = useState(false);

  // Persist state in localStorage and react state
  const useStickyState = (defaultValue, key) => {
    const [complete, setComplete] = useState(() => {
      const stickyValue = localStorage.getItem(key);

      return stickyValue !== null
        ? JSON.parse(stickyValue)
        : defaultValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(complete));
    }, [key, complete]);
    return [complete, setComplete];
  }

  let [complete, setComplete] = useStickyState(false, 'SellingHome~checkList~' + panelKey);

  // GTM
  const gtmConfig = 'https://www.principality.co.uk';

  // Toggle complete checkbox
  const handleComplete = () => {
    setComplete(!complete, 'SellingHome~checkList~' + panelKey)
    window.parent.postMessage('event~' + lang + 'SellingHome~checkList~' + panelKey + (complete ? '~incomplete' : '~complete'), gtmConfig)
  }

  return (
    <div className="panel">
      <button
        onClick={() => { setSectionActive(!sectionActive) }}
        className={
          `panelButton 
          ${complete ? 'completed' : 'not-completed'} 
          dropdown ${sectionActive ? "is-active" : 'is-inactive'}`}
      >
        {title}
      </button>

      <section className={sectionActive ? "is-active" : 'is-inactive'}>

        {children}

        <button
          onClick={handleComplete}
          className={`completedButton ${complete ? 'completed' : 'not-completed'}`}
        >
          {complete ? incompleteText : completeText}
        </button>

      </section>
    </div>
  )
}

export default Panel;
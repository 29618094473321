import React, {useEffect} from 'react';
import Panel from './Panel';

const English = () => {
  
  const gtmConfig = 'https://www.principality.co.uk';

  useEffect(() => {
    window.parent.postMessage('pageview~/virtual/principality/SellingHome-checklist', gtmConfig);

    setInterval(() => {
      window.parent.postMessage(document.body.scrollHeight, gtmConfig);
    }, 250);
  }, []);

  return (

    <div className="App">
      <div className="checklist">
        <h1>Checklist for selling your home</h1>
        <div className="intro">
          <p>Selling a house can be a long and daunting process, but being prepared and understanding the steps can help to make it as seamless as possible.</p>
          <p>Follow our checklist for selling your home and stay organised in the build up to your move.</p>
        </div>

        <div className="panelsWrapper">

          <Panel
            title="1. Get your finances in order"
            panelKey={'SHCpanelOneEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Before you sell your home, you’ll need to find out how much it’s worth. Have a look at other houses that have been sold in the last year in your area to get an idea of the price bracket.</p>
          </Panel>
          <Panel
            title="2. Choose an estate agent"
            panelKey={'SHCpanelTwoEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Do some research and choose a good, well-rated estate agent to help you sell your home. You’ll also need to work with your estate agent to decide on an asking price.</p>
          </Panel>
          <Panel
            title="3. Prepare your home for sale"
            panelKey={'SHCpanelThreeEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Tidy up, add a fresh lick of paint and make sure your home is feeling sparkly and new.</p>
          </Panel>

          <Panel
            title="4. Apply for an energy performance certificate"
            panelKey={'SHCpanelFourEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>You should also apply for an Energy Performance Certificate before you put your home on the market.</p>
          </Panel>

          <Panel
            title="5. Choose a conveyancing solicitor"
            panelKey={'SHCpanelFive'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Select a successful conveyancing solicitor within your price range to manage the legal side of your house sale.</p>
          </Panel>

          <Panel
            title="6. Accept an offer you’re happy with"
            panelKey={'SHCpanelSixEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Once you’ve received an offer from a potential buyer that you’re happy with, you can formally accept this offer. This does not make the sale legally binding.</p>
            <p>Don’t be afraid to reject offers you aren’t happy with, or ask your estate agent to negotiate a higher price.</p>
          </Panel>

          <Panel
            title="7. Exchange contracts"
            panelKey={'SHCpanelSevenEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>Once you’ve formally accepted an offer you’ll need to draft and exchange contracts with the potential buyer. Exchanging contracts makes the sale legally binding and you’re required, by law, to sell your property.</p>
          </Panel>

          <Panel
            title="8. Completion"
            panelKey={'SHCpanelEightEN'}
            completeText={'Mark as complete'}
            incompleteText={'Mark as incomplete'}
          >
            <p>It’s time to hand your keys to the new owners as completion day is when the property changes ownership.</p>
            <p>Your solicitor will usually take care of paying off your mortgage once the buyer's funds are received. However, you’ll need to settle all fees with your estate agent and solicitor.</p>
            <p>Finally, you’ve reached the end of your house sale!</p>
          </Panel>
        </div>
      </div>
    </div>
  )
};

export default English;